<template>
  <div class="page">
    <uploader
        browse_button="browse_button"
        :url="this.$globalUrl() + 'zyd-common/file/plUpload'"
        chunk_size="3MB"
        :multi_selection="false"
        :max_retries="3"
        :filters="myfilters"
        :FilesAdded="filesAdded"
        :BeforeUpload="beforeUpload"
        :Error="error"
        :headers="myHeaders"
        :UploadComplete="uploadComplete"
        :FileUploaded="fileUploaded"
        :UploadProgress="uploadProgress"
        @inputUploader="inputUploader"
    />


    <!-- :ChunkUploaded="chunkUploaded" -->
    <el-form
        size="small"
        class="query-form"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item class="f_r m_r1">
            <!-- <Upload :uploadAskObj="uploadRequire" @getDataList="getDataList"></Upload> -->
            <el-button type="primary" id="browse_button">选择文件</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="bg-white top">
      <el-menu
          :default-active="tabIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelectTab"
      >
        <el-menu-item index="0">上传中</el-menu-item>
        <el-menu-item index="1">已完成</el-menu-item>
        <el-menu-item index="2">日志</el-menu-item>
        <!-- <div style="float: right;" v-if="files.length!=0  && tabIndex==0">
            <span>{{rate}}</span>
            <el-button  v-if="!uploading" icon="el-icon-video-play"  @click="uploadStart()" type="primary">开始上传</el-button>
            <el-button  icon="el-icon-video-pause" @click="uploadStop()" type="primary" v-else >暂停上传</el-button>
        </div> -->

      </el-menu>

      <!--上传中-->
      <div v-if="tabIndex == 0" class="h100">
        <div v-if="files.length==0" class="text_center m_b2" style="margin-top: 60px">
          <img src="../../../../assets/img/upload.png">
          <!--<div class="m_t1">暂时没有上传的文件哦，快去上传吧~</div>-->
        </div>
        <div class="contentBox">
          <div class="flex_l_c item" v-for="(item, index) in files" :key="index">
            <el-button class="delBtn" icon="el-icon-close" @click="deleteFile(item.id,index)" type="primary" size="mini"
                       circle></el-button>
            <div class="uploadImg flex_c_c">
              <img src="../../../../assets/img/Scheme.png"
                   v-if="item.name.substring(item.type.indexOf('.')+1) == 'png'
                                || item.name.substring(item.type.indexOf('.')+1) == 'jpg'
                                || item.name.substring(item.type.indexOf('.')+1) == 'gif'
                            ">
              <img src="../../../../assets/img/zipIcon.png"
                   v-else-if="item.name.substring(item.type.indexOf('.')+1) == 'zip'
                                || item.name.substring(item.type.indexOf('.')+1) == 'rar'
                            ">
              <img src="../../../../assets/img/xlsIcon.png"
                   v-else-if="item.name.substring(item.type.indexOf('.')+1) == 'xls'
                                || item.name.substring(item.type.indexOf('.')+1) == 'xlsx'
                            ">
              <img src="../../../../assets/img/word.png"
                   v-else-if="item.name.substring(item.type.indexOf('.')+1) == 'doc'
                                || item.name.substring(item.type.indexOf('.')+1) == 'docx'
                            ">
              <img src="../../../../assets/img/document.png"
                   v-else>


            </div>
            <div class="flex1">
              <el-row class="m_b1">
                <el-col :span="8">{{ item.name }}</el-col>
                <!-- <el-col :span="6" class="text_center">{{item.type.substring(0,item.type.indexOf("|"))}}</el-col> -->
                <el-col :span="6" class="text_center">{{ tableData[index].eDirectoryName }}</el-col>
                <el-col :span="2" class="text_center">{{ tableData[index].rate }}</el-col>
                <el-col :span="3" class="text_center">
                  <span v-if="item.status === -1">正在计算MD5</span>
                  <span v-if="item.status === 1 && item.percent === 0">等待上传</span>
                  <span v-if="item.status === 4" style="color: brown">上传失败</span>
                  <span v-if="item.status === 5" style="color: chartreuse">已上传</span>
                  <!-- {{item.status === 2 && !percentflag  || item.status === 1 && item.percent > 0 && !percentflag}} -->
                  <el-progress
                      v-if="item.status === 2 && !percentflag  || item.status === 1 && item.percent > 0 && !percentflag "
                      :text-inside="true" :stroke-width="20" :percentage="item.percent"></el-progress>
                  <!-- {{item.status === 2 && percentflag || item.status === 1 && percentflag}} -->
                  <el-progress
                      v-if="item.status === 2 && percentflag || item.status === 1 && percentflag && item.type.substring(item.type.indexOf('|')+1)>0"
                      :text-inside="true" :stroke-width="20" :percentage="percentUp"></el-progress>
                </el-col>
                <el-col :span="5" class="text_center" v-if="item.status === 2|| item.status === 1 && item.percent > 0">
                  <span>{{ rate }}</span>
                  <el-button v-if="!uploading" icon="el-icon-video-play" @click="uploadStart()" type="primary">
                    开始上传
                  </el-button>
                  <el-button icon="el-icon-video-pause" @click="uploadStop()" type="primary" v-else>暂停上传</el-button>
                </el-col>
                <!-- <el-col :span="4" class="text_center">正在上传</el-col> -->
              </el-row>
              <!-- <el-progress :percentage="item.percent"></el-progress>-->
            </div>
          </div>
        </div>
      </div>
      <!--已完成-->
      <!-- <Finished v-if="tabIndex == 1" /> -->
      <!--上传日志-->
      <!-- <Log v-if="tabIndex == 2" /> -->
    </div>
  </div>
</template>

<script>
// import SparkMD5 from "spark-md5"
import FileMd5 from '@/utils/file-md5'
import Uploader from "@/components/Upload/Uploader"; // 导入上传组件
// import Finished from "./finished"; // 已完成
// import Log from "./log"; // 上传日志

export default {
  components: {Uploader},
  filters: {
    btnTextFilter(val) {
      return val ? '暂停' : '继续'
    }
  },
  data() {
    return {
      percent: 0,
      percentflag: false,
      percentUp: 0,
      videoUrl: '',
      percentCount: 0,
      target: [],
      srhStr: "",
      options: [],
      props: {
        checkStrictly: true,
        value: 'id',
        label: 'label',
        children: 'children'
      },
      uploadRequire: {
        //上传要求
        list: [], // 是否可以多选
        multiple: true,
        uploadPath: "",
        accept: "*",
        disabled: true,
      },
      tabIndex: "0",
      list: [
        /*{
            fileName: '展览所用文物.execl',
            affiliation: 'A展览',
            uploadStatus: true,
            fileType: '0'
        },*/
      ],
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      exhibitionName: "",
      directoryName: "",
      eDirectoryName: "",
      inputForm: {
        id: "",
        fileName: "",//文件名
        url: "",//文件路径
        actualPath: "",
        uploadTarget: "",//目录id
        quanzongNo: "",//展览id
        uploadState: "1",//上传状态
        assTypeValue: "0",//关联类型值（0：待关联，1：整编中，2，已归档）
        classification: "项目公开",//密级
        fileSize: "",//文件大小
        fileFormat: "",//文件格式
        pyear: new Date().getFullYear(),//年度
        safekeepDate: "永久",//保管期限
        respoPerson: "湖南省博物馆",//责任者
      },
      up: {},
      files: [],
      // tableData: {
      //     data:[],
      //     state:1
      // },
      log: {
        operModular: "0",//模块
        operType: "0",//类型：主版本
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      tableData: [],
      rate: "",
      uploading: false,
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      myfilters: {
        // mime_types :[{ title : "Image files", extensions : "jpg,gif,png,mp4,PDF,pdf,DOC,doc,avi" }],
        // max_file_size : '400kb', //最大只能上传400kb的文件
        prevent_duplicates: true //不允许选取重复文件},
      },
    };
  },
  // watch: {
  //     files: {
  //         handler() {
  //             this.bytesPerSec=this.up.total.bytesPerSec
  //             if(this.bytesPerSec < 0.1 * 1024){                            //小于0.1KB，则转化成B
  //                 this.rate = this.bytesPerSec.toFixed(2) + "B/s"
  //             }else if(this.bytesPerSec < 0.1 * 1024 * 1024){            //小于0.1MB，则转化成KB
  //                 this.rate = (this.bytesPerSec/1024).toFixed(2) + "KB/s"
  //             }else if(this.bytesPerSec < 0.1 * 1024 * 1024 * 1024){        //小于0.1GB，则转化成MB
  //                 this.rate = (this.bytesPerSec/(1024 * 1024)).toFixed(2) + "MB/s"
  //             }else{                                            //其他转化成GB
  //                 this.rate = (this.bytesPerSec/(1024 * 1024 * 1024)).toFixed(2) + "GB/s"
  //             }
  //             // this.tableData = [];
  //             // this.files.forEach((f) => {
  //             //     console.log("files:",f.name);
  //             //     console.log("size:",f.size);
  //             //     console.log("status:",f.status);
  //             //     console.log(this.eDirectoryName,"eDirectoryName");
  //             //     this.tableData.push({
  //             //         name: f.name,
  //             //         size: f.size,
  //             //         status: f.status,
  //             //         eDirectoryName:this.eDirectoryName,
  //             //         percent:f.percent,
  //             //         id: f.id
  //             //     });
  //             // });
  //         // console.log("tableData:",this.tableData);
  //         },
  //         deep: true
  //     }
  // },
  mounted() {
    this.percentUp = 0;
    // console.log("文件属性：",this.inputForm)
    this.$axios(this.api.infor.exhibitionInDirectoryAll, {}, 'get').then(data => {
      if (data && data.status) {
        console.log(data.data);
        this.options = this.handleSearch(data.data);
      }
    })
  },
  methods: {
    inputUploader(up) {
      this.up = up;
      this.files = up.files;
    },
    filesAdded(up, files) {
      this.percent = 0;
      files.forEach((f) => {
        f.status = -1;
        // f.type =this.eDirectoryName+"|0";
        this.tableData.push({
          eDirectoryName: this.eDirectoryName
        })
        // this.tableData.push({
        //     name: f.name,
        //     size: f.size,
        //     status: f.status,
        //     eDirectoryName:this.eDirectoryName,
        //     percent:f.percent,
        //     id: f.id
        // });
        // console.log("tableData:",this.tableData);

        FileMd5(f.getNative(), (e, md5) => {
          console.log("================md51==================", md5)
          f["md5"] = md5;
          f.status = 1;
          console.log("================fil1e==================", f)
          this.$axios(this.api.resources.getPercentage, {md5: f.md5, size: f.size}, 'get').then(res => {
            if (res.status) {
              if (res.data != 0) {
                console.log(res.data, "======================res.data=================");
                // f.type =this.eDirectoryName+"|"+res.data;
              }
              this.files.forEach((f) => {
                if (f.status != 2) {
                  this.uploadStart();
                  this.uploading = true;
                }
              })
            }
          });
        });

      });


      // this.$refs['begin'];
      // this.$refs.begin.dispatchEvent(new MouseEvent('click'));
      // this.$emit("uploadStop","")
      // let aa = setTimeout(()=>{
      //     this.uploadStart();
      // },100)


    },
    uploadProgress(uploader, file) {
      this.bytesPerSec = this.up.total.bytesPerSec
      if (this.bytesPerSec < 0.1 * 1024) {                            //小于0.1KB，则转化成B
        this.rate = this.bytesPerSec.toFixed(2) + "B/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
        this.rate = (this.bytesPerSec / 1024).toFixed(2) + "KB/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
        this.rate = (this.bytesPerSec / (1024 * 1024)).toFixed(2) + "MB/s"
      } else {                                            //其他转化成GB
        this.rate = (this.bytesPerSec / (1024 * 1024 * 1024)).toFixed(2) + "GB/s"
      }

      if (file.size < 104857600) {
        this.percent = file.percent;
      } else {
        let uploaded = file.type.substring(file.type.indexOf("|") + 1)
        this.percentflag = file.type.substring(file.type.indexOf('|') + 1) * 1000 / 1000 > 0
        if (uploaded > 0) {
          let sjs = Math.round(Math.random() * 3);
          let p = (uploaded * 1000 / 1000) + sjs;
          if (p >= 100) {
            file.type = file.type.replace(uploaded, 99)
            this.percentUp = 99;
          } else if (p < 100) {
            file.type = file.type.replace(uploaded, p)
            this.percentUp = p
          }
        }
      }
    },
    deleteFile(id, index) {
      // this.percentflag=false;
      // this.percentUp=0;
      let file = this.up.getFile(id);
      this.log.operTerm = file.name;
      this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
        // this.$emit('refreshDataList')
      })
      this.tableData.splice(index, 1);    //删除数组中的该条数据
      this.up.removeFile(file);
    },
    beforeUpload(up, file) {
      up.setOption("multipart_params", {
        "size": file.size,
        "md5": file.md5,
        "uploadPath": this.uploadRequire.uploadPath
      });
    },
    // chunkUploaded(uploader,file,responseObject){
    //     let data=JSON.parse(responseObject.response)
    //     let percentage= data.data.percentage;
    //     if(percentage>0){
    //         file.percent=percentage
    //     }
    //     console.log(file,"======================chunkUploaded.file=======================");
    //     console.log(file.percent,"======================chunkUploaded.file.percent=======================");
    // },
    fileUploaded(uploader, file, responseObject) {
      let data = JSON.parse(responseObject.response)
      console.log(responseObject, "===============上傳完成=====================")
      if (this.files !== undefined && this.files.length > 0) {
        this.inputForm.fileName = file.name
        this.inputForm.url = data.data
        this.inputForm.actualPath = data.data
        this.inputForm.fileSize = file.size
        this.inputForm.fileFormat = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length)
        this.percentflag = false;
        this.percentUp = 0;
        console.log(this.inputForm, "===============文件属性：=====================")
        this.$axios(this.api.common.fileCollectSave, this.inputForm, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(data.msg)
            this.up.removeFile(file);
            this.rate = "0.00B/s";
            // this.tabIndex="1"
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },
    uploadStart() {
      this.up.start();
      this.uploading = true;
    },
    uploadStop() {
      this.up.stop();
      this.uploading = false;
    },
    error(uploader, errObject) {
      this.uploading = false;
      switch (errObject.code) {
        case -100:
          this.$message.error("上传失败");
          break;
        case -200:
          this.$message.error("http网络错误");
          break;
        case -300:
          this.$message.error("发生磁盘读写错误时的错误代码，例如本地上某个文件不可读");
          break;
        case -400:
          this.$message.error("发生因为安全问题而产生的错误");
          break;
        case -500:
          this.$message.error("初始化时发生错误");
          break;
        case -600:
          this.$message.error("选择的文件太大");
          break;
        case -601:
          this.$message.error("选择的文件类型不符合要求");
          break;
        case -602:
          this.$message.error("选取了重复的文件");
          break;
        case -700:
          this.$message.error("图片格式错误");
          break;
        case -702:
          this.$message.error("文件大小超过系统所能处理的最大值");
          break;
        default:
          this.$message.error("内存错误");
      }
      // this.$message.error(errObject.message);
      // this.$message.error(`上传文件只能是${this.myfilters.mime_types[0].extensions}格式!`);
    },
    uploadComplete() {
      this.uploading = false;
    },
    handleSearch(data) {
      let that = this;
      data.forEach(function (v) {
        if (v.sysDirectoryManas.length === 0) {
          v.sysDirectoryManas = undefined
        }
        that.$set(v, "label", v.exhibitionName);
        that.$set(v, "children", v.sysDirectoryManas);
        if (v.children !== undefined && v.children.length > 0) {
          v.children.forEach(function (j) {
            // that.$set(j,"id",j.mlid);
            that.$set(j, "label", j.directoryName);
          })
        }
      })
      let exhibition = {
        id: 0,
        label: this.$dictUtils.getDictLabel("my_file", 0),
        children: undefined,
      }
      data.unshift(exhibition);
      console.log(data);
      return data;
    },
    // tab切换
    handleSelectTab(index) {
      this.tabIndex = index;
    },

    // 获取上传数据
    getDataList(data) {
      if (data.state == 1) {
        this.dataList = [];
        this.list = data.data;
      } else {
        this.dataList = data.data;
        this.list = [];
      }
      if (this.list.length == 0) {
        this.uploadRequire.disabled = false;
      } else {
        this.uploadRequire.disabled = true;
      }
      console.log("数据：", this.dataList);
      if (this.dataList !== undefined && this.dataList.length > 0) {
        this.inputForm.fileName = this.dataList[0].name
        this.inputForm.url = this.dataList[0].url
        this.inputForm.fileSize = this.dataList[0].size
        this.inputForm.fileFormat = this.dataList[0].type
        console.log("文件属性：", this.inputForm);

        this.$axios(this.api.common.fileCollectSave, this.inputForm, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(data.msg)
            // this.tabIndex="1"
          } else {
            this.$message.error(data.msg)
          }
        })
      }

    },
    //获取上传的文件夹
    getFolder(value) {
      console.log("123123")
      if (value.length > 0) {
        this.uploadRequire.disabled = false;
      } else {
        this.uploadRequire.disabled = true;
      }
      this.inputForm.quanzongNo = ""
      this.inputForm.uploadTarget = ""
      if (value.length == 1) {
        this.inputForm.quanzongNo = value[0]
      }
      if (value.length == 2) {
        this.inputForm.quanzongNo = value[0]
        this.inputForm.uploadTarget = value[1]
      }
      console.log("展览：", this.inputForm.quanzongNo);
      console.log("目录：", this.inputForm.uploadTarget);
      this.uploadRequire.uploadPath = this.recursive()
      console.log("上传路劲：", this.uploadRequire.uploadPath);
    },
    recursive() {

      let that = this;

      that.exhibitionName = ""
      that.directoryName = "a"
      that.options.forEach(function (v) {
        if (that.inputForm.quanzongNo !== '' && that.inputForm.quanzongNo == v.id) {
          that.exhibitionName = v.label
        }
        if (v.children) {
          v.children.forEach(function (v) {
            if (that.inputForm.uploadTarget !== '' && that.inputForm.uploadTarget == v.id) {
              that.directoryName = v.label
            }
          })
        }
      })
      // console.log(that.directoryName=="a","============directoryName");
      this.eDirectoryName = that.directoryName === "a" ? that.exhibitionName : that.exhibitionName + "/" + that.directoryName;
      // return that.exhibitionName+"/"+that.directoryName
      return that.exhibitionName
    }
  },
};
</script>

<style scoped>
.contentBox {
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.contentBox .item {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0px 0px 6px #cecece;
  margin: 20px 80px 40px 5px;
  padding: 10px 20px 10px 0px;
  position: relative;
}

.item >>> .el-button.el-button--text {
  padding: 0;
}

.contentBox .delBtn {
  position: absolute;
  right: -10px;
  top: -10px;
}

.uploadImg {
  width: 100px;
}

.uploadImg img {
  width: 32px;
  height: 32px;
}

.progress-box {
  box-sizing: border-box;
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 8px 10px;
  background-color: #ecf5ff;
  font-size: 14px;
  border-radius: 4px;
}
</style>
