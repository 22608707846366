var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('uploader',{attrs:{"browse_button":"browse_button","url":this.$globalUrl() + 'zyd-common/file/plUpload',"chunk_size":"3MB","multi_selection":false,"max_retries":3,"filters":_vm.myfilters,"FilesAdded":_vm.filesAdded,"BeforeUpload":_vm.beforeUpload,"Error":_vm.error,"headers":_vm.myHeaders,"UploadComplete":_vm.uploadComplete,"FileUploaded":_vm.fileUploaded,"UploadProgress":_vm.uploadProgress},on:{"inputUploader":_vm.inputUploader}}),_c('el-form',{staticClass:"query-form",attrs:{"size":"small"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"f_r m_r1"},[_c('el-button',{attrs:{"type":"primary","id":"browse_button"}},[_vm._v("选择文件")])],1)],1)],1)],1),_c('div',{staticClass:"bg-white top"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.tabIndex,"mode":"horizontal"},on:{"select":_vm.handleSelectTab}},[_c('el-menu-item',{attrs:{"index":"0"}},[_vm._v("上传中")]),_c('el-menu-item',{attrs:{"index":"1"}},[_vm._v("已完成")]),_c('el-menu-item',{attrs:{"index":"2"}},[_vm._v("日志")])],1),(_vm.tabIndex == 0)?_c('div',{staticClass:"h100"},[(_vm.files.length==0)?_c('div',{staticClass:"text_center m_b2",staticStyle:{"margin-top":"60px"}},[_c('img',{attrs:{"src":require("../../../../assets/img/upload.png")}})]):_vm._e(),_c('div',{staticClass:"contentBox"},_vm._l((_vm.files),function(item,index){return _c('div',{key:index,staticClass:"flex_l_c item"},[_c('el-button',{staticClass:"delBtn",attrs:{"icon":"el-icon-close","type":"primary","size":"mini","circle":""},on:{"click":function($event){return _vm.deleteFile(item.id,index)}}}),_c('div',{staticClass:"uploadImg flex_c_c"},[(item.name.substring(item.type.indexOf('.')+1) == 'png'
                              || item.name.substring(item.type.indexOf('.')+1) == 'jpg'
                              || item.name.substring(item.type.indexOf('.')+1) == 'gif'
                          )?_c('img',{attrs:{"src":require("../../../../assets/img/Scheme.png")}}):(item.name.substring(item.type.indexOf('.')+1) == 'zip'
                              || item.name.substring(item.type.indexOf('.')+1) == 'rar'
                          )?_c('img',{attrs:{"src":require("../../../../assets/img/zipIcon.png")}}):(item.name.substring(item.type.indexOf('.')+1) == 'xls'
                              || item.name.substring(item.type.indexOf('.')+1) == 'xlsx'
                          )?_c('img',{attrs:{"src":require("../../../../assets/img/xlsIcon.png")}}):(item.name.substring(item.type.indexOf('.')+1) == 'doc'
                              || item.name.substring(item.type.indexOf('.')+1) == 'docx'
                          )?_c('img',{attrs:{"src":require("../../../../assets/img/word.png")}}):_c('img',{attrs:{"src":require("../../../../assets/img/document.png")}})]),_c('div',{staticClass:"flex1"},[_c('el-row',{staticClass:"m_b1"},[_c('el-col',{attrs:{"span":8}},[_vm._v(_vm._s(item.name))]),_c('el-col',{staticClass:"text_center",attrs:{"span":6}},[_vm._v(_vm._s(_vm.tableData[index].eDirectoryName))]),_c('el-col',{staticClass:"text_center",attrs:{"span":2}},[_vm._v(_vm._s(_vm.tableData[index].rate))]),_c('el-col',{staticClass:"text_center",attrs:{"span":3}},[(item.status === -1)?_c('span',[_vm._v("正在计算MD5")]):_vm._e(),(item.status === 1 && item.percent === 0)?_c('span',[_vm._v("等待上传")]):_vm._e(),(item.status === 4)?_c('span',{staticStyle:{"color":"brown"}},[_vm._v("上传失败")]):_vm._e(),(item.status === 5)?_c('span',{staticStyle:{"color":"chartreuse"}},[_vm._v("已上传")]):_vm._e(),(item.status === 2 && !_vm.percentflag  || item.status === 1 && item.percent > 0 && !_vm.percentflag )?_c('el-progress',{attrs:{"text-inside":true,"stroke-width":20,"percentage":item.percent}}):_vm._e(),(item.status === 2 && _vm.percentflag || item.status === 1 && _vm.percentflag && item.type.substring(item.type.indexOf('|')+1)>0)?_c('el-progress',{attrs:{"text-inside":true,"stroke-width":20,"percentage":_vm.percentUp}}):_vm._e()],1),(item.status === 2|| item.status === 1 && item.percent > 0)?_c('el-col',{staticClass:"text_center",attrs:{"span":5}},[_c('span',[_vm._v(_vm._s(_vm.rate))]),(!_vm.uploading)?_c('el-button',{attrs:{"icon":"el-icon-video-play","type":"primary"},on:{"click":function($event){return _vm.uploadStart()}}},[_vm._v(" 开始上传 ")]):_c('el-button',{attrs:{"icon":"el-icon-video-pause","type":"primary"},on:{"click":function($event){return _vm.uploadStop()}}},[_vm._v("暂停上传")])],1):_vm._e()],1)],1)],1)}),0)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }